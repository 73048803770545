import { useEffect, useState } from 'react';
import { Row, Col, Button, Table, message, Skeleton, Typography, Tag } from 'antd';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import JSZip from 'jszip';
import { BASE_URL as BASEURL } from '../../Constants/URLs';
import LayoutComponent from '../Components/Layout';
import { getAPI } from '../../Services/Api';
import { DisplayJson, downloadFile, getDateTimeString } from '../../Utils/HelperFunctions';
import {
  GET_STATUS,
  GET_STATUS_COLORS,
  REQUEST_STATUS_VALUES,
  STEP_LINKS,
  STEP_NAMES
} from '../../Constants/Constants';
import { DownloadOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { EditOutlined, CheckOutlined } from '@ant-design/icons';
import AlertModal from '../../Components/AlertModal';
const InDetail = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [downloadBtnLoading, setDownloadBtnLoading] = useState(false);
  const [steps, setSteps] = useState([]);
  const [editedRow, setEditedRow] = useState('');
  const [editedText, setEditedText] = useState('');
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleEditClick = text => {
    setEditedRow(text);
    setEditedText(text);
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
    setEditedRow('');
    setEditedText('');
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const tableColumns = [
    {
      title: 'ID',
      key: 'job_id',
      dataIndex: 'job_id',
      render: (text, record) =>
        record.step_name === STEP_NAMES.FETCHING_IMAGERY ? (
          <div>
            {editedRow === text ? (
              <div className='flex-row-center '>
                <Input.TextArea
                  value={editedText}
                  rows={1}
                  style={{ resize: 'none' }}
                  onChange={e => setEditedText(e.target.value)}
                />
                <CheckOutlined className='icon-style ml' onClick={() => setIsOpenModal(true)} />
              </div>
            ) : (
              <div>{text}</div>
            )}
            {!(editedRow === text) && <EditOutlined className='icon-style' onClick={() => handleEditClick(text)} />}
          </div>
        ) : (
          <div>{text}</div>
        )
    },
    {
      title: 'Name',
      key: 'step_name',
      dataIndex: 'step_name',
      render: (text, record) => (
        <a href={STEP_LINKS[text]} target='_blank' rel='noreferrer'>
          {text}
        </a>
      )
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      ellipsis: true,
      render: (text, record) => <p>{getDateTimeString(text['$date'], true)}</p>,
      sorter: {
        compare: (a, b) => new Date(a.created_at) - new Date(b.created_at)
      },
      sortDirections: ['descend', 'ascend']
    },
    {
      title: '',
      key: 'download_btn',
      dataIndex: 'output',
      render: (output, record) => (
        <div className='txt-aln-ctr'>
          <Button
            icon={<DownloadOutlined />}
            className='half-width'
            disabled={!output}
            onClick={() => onDownload(output, record.step_name)}></Button>
        </div>
      )
    }
  ];

  const getData = () => {
    let url = BASEURL + '/request/' + id + '/output';
    getAPI(url)
      .then(res => {
        setData(res);
        const stepsResponse = res?.pipeline_run?.steps
          // this is done to skip internal orchestrator steps and post processing steps
          .filter(eachStep => {
            let job_id = eachStep.job_id ?? eachStep.meta_data.job_id;
            if (typeof job_id === 'object' || job_id === undefined) {
              return false;
            }
            return true;
          })
          .map(eachStep => {
            let job_id = eachStep.job_id ?? eachStep.meta_data.job_id;
            return { ...eachStep, job_id };
          });
        setSteps(stepsResponse);
      })
      .finally(() => setIsLoading(false));
  };

  const onDownload = (downloadOutput = null, stepName = null) => {
    setDownloadBtnLoading(true);

    var zip = new JSZip();
    var idZip = zip.folder(stepName ? stepName + '_' + id : id);
    let index = data.pipeline_run.current_step_index;
    if (data?.pipeline_run?.status !== REQUEST_STATUS_VALUES.COMPLETED && index !== 0) {
      index -= 1; // - 1 to get the last step
    }
    let output = downloadOutput ? downloadOutput : data.pipeline_run.steps[index].output;

    if (output === null) {
      setDownloadBtnLoading(false);
      message.error('No data to download.');
      return;
    }
    for (const [key, value] of Object.entries(output)) {
      idZip.file(key + '.geojson', JSON.stringify(value));
    }

    idZip.file('modified_parcel.geojson', JSON.stringify(data.pipeline_run.modified_parcel));

    idZip.generateAsync({ type: 'blob' }).then(
      function (blob) {
        //    saveAs(blob, (stepName ? stepName + '_' + id : id) + '.zip');
        downloadFile(blob, (stepName ? stepName + '_' + id : id) + '.zip');
        setDownloadBtnLoading(false);
      },
      function (err) {
        message.error('There was some problem downloading the file');
      }
    );
  };

  return (
    <LayoutComponent>
      <AlertModal visible={isOpenModal} handleIsVisible={handleCloseModal} requestId={data?._id} jobId={editedText} />
      <Row>
        <Col sm={24} md={8}>
          <Skeleton loading={isLoading} avatar active title round>
            <div className='img-container'>
              <a href={data?.pipeline_run?.png_image_url} target='_blank' rel='noreferrer'>
                <img loading='lazy' src={data?.pipeline_run?.png_image_url} alt='img' />
              </a>
            </div>
          </Skeleton>
        </Col>
        <Col sm={24} md={16}>
          <Row gutter={8}>
            <Col xs={24} sm={12} md={8}>
              <Skeleton loading={isLoading} active title round>
                <div className='card'>
                  <Typography.Title ellipsis mark level={5} title={data?._id}>
                    {data?._id}
                  </Typography.Title>
                </div>
              </Skeleton>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Skeleton loading={isLoading} active title round>
                <div className='card'>
                  <Typography.Title level={5}>Updated At: {getDateTimeString(data?.updated_at, true)}</Typography.Title>
                </div>
              </Skeleton>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Skeleton loading={isLoading} active title round>
                <div className='card'>
                  <Typography.Title level={5}>Source Type: {data?.source_type}</Typography.Title>
                </div>
              </Skeleton>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Skeleton loading={isLoading} active title round>
                <div className='card'>
                  <Typography.Title level={5}>
                    Status:{' '}
                    <Tag color={GET_STATUS_COLORS[data?.pipeline_run?.status]}>
                      {GET_STATUS[data?.pipeline_run?.status]}
                    </Tag>
                  </Typography.Title>
                </div>
              </Skeleton>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Skeleton loading={isLoading} active title round>
                <div className='card'>
                  <Typography.Title level={5}>Layer: {data?.pipeline_run?.layer || 'null'}</Typography.Title>
                </div>
              </Skeleton>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Skeleton loading={isLoading} active title round>
                <div className='card'>
                  <Link to={`/request/raw/${id}`} state={data}>
                    <Button type='primary' ghost>
                      View Raw
                    </Button>
                  </Link>
                  {data.pipeline_run?.status !== REQUEST_STATUS_VALUES.PENDING && (
                    <Button type='primary' loading={downloadBtnLoading} onClick={() => onDownload()}>
                      Download Zip
                    </Button>
                  )}
                </div>
              </Skeleton>
            </Col>
          </Row>

          <Row gutter={6}>
            <Col xs={24}>
              <Skeleton loading={isLoading} active title round>
                <span className='shadow'>
                  <Typography.Title level={3}>Features</Typography.Title>
                  <DisplayJson data={data?.input?.features} />
                </span>
              </Skeleton>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col xs={24}>
          <Skeleton loading={isLoading} active title round>
            <span className='shadow'>
              <Typography.Title level={3}>Job ID mapping</Typography.Title>
              <Table columns={tableColumns} rowKey={obj => obj.job_id} dataSource={steps} bordered />
            </span>
          </Skeleton>
        </Col>
      </Row>

      <Row gutter={2}>
        <Col xs={24}>
          <Skeleton loading={isLoading} active title round>
            <span className='shadow'>
              <Typography.Title level={3}>Image Details</Typography.Title>
              <DisplayJson data={data?.pipeline_run?.image} />
            </span>
          </Skeleton>
        </Col>
      </Row>

      <Row gutter={7}>
        <Col xs={24} md={12}>
          <Skeleton loading={isLoading} active title round>
            <span className='shadow'>
              <Typography.Title level={3}>Boundary Geojson</Typography.Title>
              <DisplayJson data={data?.input?.boundary_geojson} isCopyNeeded />
            </span>
          </Skeleton>
        </Col>
        <Col xs={24} md={12}>
          <Skeleton loading={isLoading} active title round>
            <span className='shadow'>
              <Typography.Title level={3}>Modified Parcel</Typography.Title>
              <DisplayJson data={data?.pipeline_run?.modified_parcel} isCopyNeeded />
            </span>
          </Skeleton>
        </Col>
      </Row>
    </LayoutComponent>
  );
};

export default InDetail;
